import { Link } from "gatsby"
import styled from "styled-components"

export const StyledContainer = styled(Link)`
  @extend .row, .center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  min-height: 0;
  padding: 0;
  margin-top: 40px;
  margin-bottom: 50px;
  text-decoration: none;
`

export const StyledBox = styled.div`
  width: 100%;
  min-height: 0;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 5px;
`

export const StyledTitle = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.family.kowaski};
  font-size: 24px;
  margin: auto;
  margin-top: 20px;
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    font-size: 33px;
  }
`

export const StyledSubTitle = styled.h2`
  color: ${({ theme }) => theme.colors.navIcons};
  font-size: 18px;
  margin: auto;
  width: 80%;
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    width: 80%;
    font-size: 24px;
  }
`

export const StyledImage = styled.img`
  width: 60%;
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    width: 80%;
  }
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    width: 50%;
  }
`
