import React from "react"
import {
  StyledBox,
  StyledContainer,
  StyledImage,
  StyledSubTitle,
  StyledTitle,
} from "./Style"
const NotfoundImage = "/images/404_not_found.svg"

const NotFound = ({ to }) => (
  <StyledContainer to={to}>
    <StyledImage src={NotfoundImage} alt="Not Found" />
    <StyledBox>
      <StyledTitle>NOT FOUND</StyledTitle>
      <StyledSubTitle>
        You just hit a route that doesn&#39;t exist... the sadness.
      </StyledSubTitle>
    </StyledBox>
  </StyledContainer>
)

export default NotFound
