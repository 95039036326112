import React from "react"
import Layout from "../../layouts/Layout"
import Helmet from "react-helmet"
import NotFound from "../../components/NotFound/NotFound"

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>404 No encontrado</title>
      <meta name="robots" content="noindex" />
      <meta name="googlebot" content="noindex" />
    </Helmet>
    <NotFound to="/es/" />
  </Layout>
)

export default NotFoundPage
